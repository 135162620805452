import React, { Fragment, useState, useRef, useCallback } from 'react'
import { createPortal } from 'react-dom'
import { Link, navigate } from 'gatsby'
import { Helmet } from 'react-helmet'
import { DocSearchButton, useDocSearchKeyboardEvents } from '@docsearch/react'

import './DocSearch.css'

let DocSearchModal = null

function Hit({ hit, children }) {
  return <Link to={hit.url}>{children}</Link>
}

function DocSearch(props) {
  const searchButtonRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [initialQuery, setInitialQuery] = useState(null)

  const importDocSearchModalIfNeeded = useCallback(() => {
    if (DocSearchModal) {
      return Promise.resolve()
    }

    return Promise.all([
      import('@docsearch/react/modal'),
      import('@docsearch/react/style/modal'),
      import('./DocSearchModal.css'),
    ]).then(([{ DocSearchModal: Modal }]) => {
      DocSearchModal = Modal
    })
  }, [])

  const onOpen = useCallback(() => {
    importDocSearchModalIfNeeded().then(() => {
      setIsOpen(true)
    })
  }, [importDocSearchModalIfNeeded, setIsOpen])

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const onInput = useCallback(
    (event) => {
      importDocSearchModalIfNeeded().then(() => {
        setIsOpen(true)
        setInitialQuery(event.key)
      })
    },
    [importDocSearchModalIfNeeded, setIsOpen, setInitialQuery]
  )

  useDocSearchKeyboardEvents({
    isOpen,
    onOpen,
    onClose,
    onInput,
    searchButtonRef,
  })

  return (
    <Fragment>
      <Helmet>
        {/* This hints the browser that the website will load data from Algolia,
        and allows it to preconnect to the DocSearch cluster. It makes the first
        query faster, especially on mobile. */}
        <link
          rel="preconnect"
          href={`https://${props.appId}-dsn.algolia.net`}
          crossOrigin="true"
        />
      </Helmet>

      <DocSearchButton
        onTouchStart={importDocSearchModalIfNeeded}
        onFocus={importDocSearchModalIfNeeded}
        onMouseOver={importDocSearchModalIfNeeded}
        onClick={onOpen}
        ref={searchButtonRef}
      />

      {DocSearchModal &&
        isOpen &&
        createPortal(
          <DocSearchModal
            initialScrollY={window.scrollY}
            initialQuery={initialQuery}
            onClose={onClose}
            navigator={{
              navigate({ suggestionUrl }) {
                navigate(suggestionUrl)
              },
            }}
            hitComponent={Hit}
            placeholder="Search posts"
            transformItems={(items) => {
              return items.map((item) => {
                // We transform the absolute URL into a relative URL to
                // leverage Gatsby's preloading.
                const a = document.createElement('a')
                a.href = item.url

                return {
                  ...item,
                  hierarchy: {
                    ...item.hierarchy,
                    lvl0: item.hierarchy.lvl0
                      ? item.hierarchy.lvl0.replace(/&#x27;/, '’')
                      : item.hierarchy.lvl0,
                  },
                  url: `${a.pathname}${a.hash}`,
                }
              })
            }}
            {...props}
          />,
          document.body
        )}
    </Fragment>
  )
}

export default DocSearch
