/** @jsx jsx */
import { jsx } from '@emotion/core'

import { createHorizontalLines } from '../utils/theme'
import { rhythm } from '../utils/typography'

const Header = ({ children }) => (
  <header
    css={{
      padding: `48px ${rhythm(1 / 2)}`,
      backgroundImage: `
        linear-gradient(90deg, rgba(0,0,0, 0.03) 0%, rgba(0,0,0, 0.03) 100%),
        ${createHorizontalLines()}`,
      '@media (min-width: 560)': {
        padding: '48px 86px',
      },
    }}
  >
    <div
      css={{
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        maxWidth: 750,
        padding: `${rhythm(1 / 1.5)} 0 ${rhythm(1 / 2)} 0`,
      }}
    >
      {children}
    </div>
  </header>
)

export default Header
