/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'

import { Tooltip } from './Tooltip'
import GitHubSvg from './vectors/GitHubSvg'

export function GitHubButton({ githubUsername, tooltipPosition }) {
  return (
    <Tooltip label="GitHub" position={tooltipPosition}>
      <a
        href={`https://github.com/${githubUsername}`}
        css={{
          color: 'rgb(0 0 0 / 50%)',
          transition: 'color 100ms',
          '&:hover': {
            color: '#000',
          },
        }}
      >
        <GitHubSvg size="1.2em" />
      </a>
    </Tooltip>
  )
}

GitHubButton.propTypes = {
  githubUsername: PropTypes.string.isRequired,
  tooltipPosition: PropTypes.string,
}
