/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'

import { Tooltip } from './Tooltip'
import PolyworkSvg from './vectors/PolyworkSvg'

export function PolyworkButton({ polyworkUsername, tooltipPosition }) {
  return (
    <Tooltip label="Polywork" position={tooltipPosition}>
      <a
        href={`https://polywork.com/${polyworkUsername}`}
        css={{
          color: 'rgb(0 0 0 / 50%)',
          transition: 'color 100ms',
          '&:hover': {
            color: '#6776f9',
          },
        }}
      >
        <PolyworkSvg size="1.1em" />
      </a>
    </Tooltip>
  )
}

PolyworkButton.propTypes = {
  polyworkUsername: PropTypes.string.isRequired,
  tooltipPosition: PropTypes.string,
}
