import Typography from 'typography'

const Theme = {
  title: 'Theme',
  baseFontSize: '18px',
  baseLineHeight: 1.8,
  scaleRatio: 2.5,
  headerFontFamily: [
    'Roboto',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Helvetica',
    'Arial',
    'sans-serif',
  ],
  bodyFontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Helvetica',
    'Arial',
    'sans-serif',
  ],
  headerColor: 'rgba(0,0,0,.9)',
  bodyColor: 'rgba(0,0,0,.75)',
  headerWeight: 700,
  bodyWeight: 400,
  boldWeight: 700,
  googleFonts: [
    {
      name: 'Roboto',
      styles: ['400', '700'],
    },
  ],
  overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => ({
    'ul,ol': {
      marginLeft: 0,
    },
    'h1,h2,h3,h4,h5,h6': {
      marginTop: rhythm(2),
    },
    h4: {
      letterSpacing: '-0.003em',
      textTransform: 'uppercase',
      color: '#666',
    },
    h6: {
      fontStyle: 'italic',
    },
    a: {
      color: '#007acc',
      textDecoration: 'none',
    },
  }),
}

const typography = new Typography(Theme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
