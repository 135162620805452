export const createHorizontalLines = (padding = 48) => `
linear-gradient(
  to bottom,
  rgb(117 171 207 / 32%) 0px,
  rgba(255,255,255,0) 1px,
  rgba(255,255,255,0) ${padding - 1}px,
  rgb(117 171 207 / 32%) ${padding}px,
  rgba(255,255,255,0) ${padding}px
),
linear-gradient(
  to top,
  rgb(117 171 207 / 32%) 0px,
  rgba(255,255,255,0) 1px,
  rgba(255,255,255,0) ${padding - 1}px,
  rgb(117 171 207 / 32%) ${padding}px,
  rgba(255,255,255,0) ${padding}px
)`
