/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import Link from 'gatsby-link'
import PropTypes from 'prop-types'

import { Tooltip } from './Tooltip'
import LogoSvg from './vectors/LogoSvg'

export function HomeButton({ tooltipPosition }) {
  const [isLogoHovered, setIsLogoHovered] = React.useState(false)

  return (
    <Tooltip label="Home" position={tooltipPosition}>
      <Link
        to="/"
        css={{
          color: '#777',
        }}
        onMouseEnter={() => setIsLogoHovered(true)}
        onMouseLeave={() => setIsLogoHovered(false)}
      >
        <LogoSvg
          size="1.4em"
          svgId="Footer"
          color={isLogoHovered ? '' : 'currentColor'}
        />
      </Link>
    </Tooltip>
  )
}

HomeButton.propTypes = {
  tooltipPosition: PropTypes.string,
}
