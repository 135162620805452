/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'

import { GitHubButton } from './GitHubButton'
import { TwitterButton } from './TwitterButton'
import { HomeButton } from './HomeButton'
import { rhythm } from '../utils/typography'
import { createHorizontalLines } from '../utils/theme'
import { PolyworkButton } from './PolyworkButton'

const Footer = ({
  githubUsername,
  twitterUsername,
  polyworkUsername,
  isHome,
}) => {
  return (
    <footer
      css={{
        padding: `${rhythm(1)} ${rhythm(1 / 2)}`,
        backgroundImage: `
        linear-gradient(90deg, rgba(0,0,0,0.03) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.03) 100%),
        ${createHorizontalLines()}`,
      }}
    >
      <ul
        css={{
          minHeight: 100,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 0,
          listStyle: 'none',
          '& li': {
            margin: 0,
            lineHeight: 0,
          },
          '& li:not(:last-of-type)': {
            marginRight: rhythm(1 / 2),
          },
        }}
      >
        {!isHome && (
          <li>
            <HomeButton tooltipPosition="top" />
          </li>
        )}
        <li>
          <GitHubButton githubUsername={githubUsername} tooltipPosition="top" />
        </li>
        <li>
          <TwitterButton
            twitterUsername={twitterUsername}
            tooltipPosition="top"
          />
        </li>
        <li>
          <PolyworkButton
            polyworkUsername={polyworkUsername}
            tooltipPosition="top"
          />
        </li>
      </ul>
    </footer>
  )
}

Footer.propTypes = {
  githubUsername: PropTypes.string.isRequired,
  twitterUsername: PropTypes.string.isRequired,
  polyworkUsername: PropTypes.string.isRequired,
}

export default Footer
