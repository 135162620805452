import React from 'react'
import ReachTooltip from '@reach/tooltip'
import '@reach/tooltip/styles.css'

const getCenteredPosition = ({ position }) => (triggerRect, tooltipRect) => {
  if (typeof window === 'undefined') {
    return {}
  }

  const triggerCenter = triggerRect.left + triggerRect.width / 2
  const left = triggerCenter - tooltipRect.width / 2
  const maxLeft = window.innerWidth - tooltipRect.width - 2

  if (position === 'top') {
    return {
      left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
      top: triggerRect.top - tooltipRect.height - 20 + window.scrollY,
    }
  }

  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.bottom + 8 + window.scrollY,
  }
}

export function Tooltip({ children, position = 'bottom', ...props }) {
  return (
    <ReachTooltip
      position={getCenteredPosition({ position })}
      {...props}
      css={{
        background: '#444',
        border: 'solid 1px #333',
        borderRadius: 10,
        boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.16)',
        color: '#fff',
        opacity: 0.8,
        padding: '0.35em 0.75em',
      }}
    >
      {children}
    </ReachTooltip>
  )
}
