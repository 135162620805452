import React from 'react'
import PropTypes from 'prop-types'

const Logo = ({ size = '1em', svgId, color, ...props }) => (
  <svg {...props} width={size} height={size} viewBox="0 0 420 485">
    <defs>
      <linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        id={`logo-${svgId}-linearGradient`}
      >
        <stop stopColor="#e4c4ff" offset="0%" />
        <stop stopColor="#8042d6" offset="100%" />
      </linearGradient>

      <polygon
        id={`logo-${svgId}-path`}
        points="210 0 420 121.25 420 363.75 210 485 1.13686838e-13 363.75 -2.13162821e-14 121.25"
      />
    </defs>

    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <g>
          <mask id={`logo-${svgId}-mask`} fill="white">
            <use xlinkHref={`#logo-${svgId}-path`} />
          </mask>

          <use
            fill={color || `url(#logo-${svgId}-linearGradient)`}
            xlinkHref={`#logo-${svgId}-path`}
          />

          <polygon
            fill="#fff"
            opacity="0.1"
            mask={`url(#logo-${svgId}-mask)`}
            points="2.27674679e-16 122 210 240.474095 209.992826 485 0 364.002989"
          />
          <polygon
            fillOpacity="0.2"
            fill="#fff"
            mask={`url(#logo-${svgId}-mask)`}
            points="0 121.26865 210.103204 0 420 121.159603 210.103204 240"
          />
        </g>
      </g>
    </g>
  </svg>
)

Logo.propTypes = {
  size: PropTypes.string,
  svgId: PropTypes.string.isRequired,
  color: PropTypes.string,
}

export default Logo
