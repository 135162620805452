/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'

import { Tooltip } from './Tooltip'
import TwitterSvg from './vectors/TwitterSvg'

export function TwitterButton({ twitterUsername, tooltipPosition }) {
  return (
    <Tooltip label="Twitter" position={tooltipPosition}>
      <a
        href={`https://twitter.com/${twitterUsername}`}
        css={{
          color: 'rgb(0 0 0 / 50%)',
          transition: 'color 100ms',
          '&:hover': {
            color: '#00acee',
          },
        }}
      >
        <TwitterSvg size="1.2em" />
      </a>
    </Tooltip>
  )
}

TwitterButton.propTypes = {
  twitterUsername: PropTypes.string.isRequired,
  tooltipPosition: PropTypes.string,
}
